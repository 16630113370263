import { Inject, Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { SchoolUserService } from '@memberspot/admin/shared/data-access/school-user';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { APP_VERSION } from '@memberspot/frontend/shared/util/tokens';
import {
  AdminUserCourseEndpoints,
  CF,
  DripMessageData,
  ResendUserWelcomeMessageData,
  UpdateDripMessagesData,
} from '@memberspot/models';
import type { UpdateAdminLastLoginDto } from '@memberspot/shared/model/admin-user';
import { AdminUserEndpoints } from '@memberspot/shared/model/admin-user';
import { PaginateResult } from '@memberspot/shared/model/backend';
import {
  CourseProgress,
  LastActions,
  UserChapter,
  UserCourse,
} from '@memberspot/shared/model/course';
import { UserCustomPropertyValue } from '@memberspot/shared/model/custom-user-property';
import { Offer } from '@memberspot/shared/model/offer';
import { SchoolOwner } from '@memberspot/shared/model/permission';
import { School } from '@memberspot/shared/model/school';
import type {
  AddUsersDto,
  EditUser,
  SchoolUserNew,
  SearchUserDto,
  UpdateOrderIdDto,
  UpdateStartDateDto,
  UpdateUserAccessExpiryDto,
  UserHasCourseNew,
  UserHasOfferSelect,
  UserSearchResult,
  UserToAdd,
} from '@memberspot/shared/model/school-user';
import { SchoolUserEndpoints } from '@memberspot/shared/model/school-user';
import { DateHelpers } from '@memberspot/shared/util/helper';
import { WINDOW } from '@ng-web-apis/common';
import { Observable, tap } from 'rxjs';

import { UserAccessExpiryChanged } from './models/user-chapter-expiry';

@Injectable({
  providedIn: 'root',
})
export class UserAdminService {
  constructor(
    private _afs: Firestore,
    private _fns: Functions,
    private _schoolsQuery: SchoolsQuery,
    private _http: HttpBackendService,
    private _schoolUserService: SchoolUserService,
    @Inject(APP_VERSION) private _appVersion: string,
    @Inject(WINDOW) private _window: Window,
  ) {}

  get schoolId() {
    const value = this._schoolsQuery.getActiveId();

    if (!value) {
      throw new Error('NO Active school');
    }

    return value;
  }

  searchUsers(
    data: SearchUserDto,
  ): Observable<PaginateResult<UserSearchResult>> {
    return this._http.generic(SchoolUserEndpoints.SEARCH(this.schoolId, data));
  }

  exportUsers(data: SearchUserDto): Observable<any> {
    return this._http.generic(SchoolUserEndpoints.EXPORT(this.schoolId, data));
  }

  resendWelcomeMessages(): Observable<any> {
    return this._http.generic(
      SchoolUserEndpoints.RESEND_WELCOME_MESSAGES(this.schoolId),
    );
  }

  checkIfUserExists(email: string): Observable<boolean> {
    return this._http.generic(
      SchoolUserEndpoints.DOES_USER_EXIST(this.schoolId, {
        eMail: email,
      }),
    );
  }

  getUserCourse(schoolId: string, courseId: string) {
    return this._http.generic<UserCourse>(
      AdminUserCourseEndpoints.USER_COURSE.GET_USER_COURSE({
        schoolId: schoolId,
        courseId: courseId,
      }),
    );
  }

  getUserChapter(schoolId: string, courseId: string, chapterId: string) {
    return this._http.generic<UserChapter>(
      AdminUserCourseEndpoints.USER_COURSE.GET_USER_CHAPTER({
        schoolId: schoolId,
        courseId: courseId,
        chapterId: chapterId,
      }),
    );
  }

  getUserCourseprogress(schoolId: string, uid: string, courseId: string) {
    return this._http.generic<CourseProgress>(
      AdminUserCourseEndpoints.USER_PROGRESS.GET_COURSE_PROGRESS({
        schoolId: schoolId,
        courseId: courseId,
        uid: uid,
      }),
    );
  }

  getUserChapterProgress(
    schoolId: string,
    courseId: string,
    chapterId: string,
    uid: string,
  ) {
    return this._http.generic(
      AdminUserCourseEndpoints.USER_PROGRESS.GET_CHAPTER_PROGRESS(
        schoolId,
        courseId,
        chapterId,
        uid,
      ),
    );
  }

  resetUserPost(
    courseId: string,
    chapterId: string,
    postId: string,
    uid: string,
    clearPost?: boolean,
  ) {
    return this._http.generic(
      AdminUserCourseEndpoints.USER_PROGRESS.RESET_USER_POST({
        schoolId: this.schoolId,
        courseId,
        chapterId,
        postId,
        uid,
        clearPost,
      }),
    );
  }

  setCompleteUserPost(
    courseId: string,
    chapterId: string,
    postId: string,
    uid: string,
  ) {
    return this._http.generic(
      AdminUserCourseEndpoints.USER_PROGRESS.COMPLETE_USER_POST({
        schoolId: this.schoolId,
        courseId,
        chapterId,
        postId,
        uid,
      }),
    );
  }

  getUserLastActions(schoolId: string, uid: string, courseId: string) {
    return this._http.generic<LastActions>(
      AdminUserCourseEndpoints.USER_COURSE.GET_LAST_ACTIONS({
        schoolId,
        uid,
        courseId,
      }),
    );
  }

  updateSchoolUserAccess(
    uid: string,
    hasOfferId: string,
    offerId: string,
    sendMessage: boolean,
    active?: boolean,
  ) {
    const action = SchoolUserEndpoints.UPDATE_USER_ACCESS({
      schoolId: this.schoolId,
      uid,
      hasOfferId,
      offerId,
      sendMessage,
      active,
    });

    return this._http.generic(action);
  }

  removeOfferFromUsers(
    schoolId: string,
    offerId: string,
    data: UserHasOfferSelect[],
    sendMessage: boolean,
  ) {
    return this._http.generic(
      SchoolUserEndpoints.REMOVE_OFFER_FOR_USERS(
        schoolId,
        offerId,
        data,
        sendMessage,
      ),
    );
  }

  deleteUser(schoolId: string, uid: string) {
    return this._http.generic(SchoolUserEndpoints.DELETE_USER(schoolId, uid));
  }

  deleteMultipleUsers(schoolId: string, uids: string[]) {
    return this._http.generic(
      SchoolUserEndpoints.DELETE_MULTIPLE_USERS(schoolId, { schoolId, uids }),
    );
  }

  removeUserDevice(schoolId: string, uid: string, visitorId: string) {
    return this._http.generic(
      SchoolUserEndpoints.REMOVE_DEVICE(schoolId, { uid, visitorId }),
    );
  }

  addUsers(users: UserToAdd[]) {
    const dto: AddUsersDto = {
      users,
      schoolId: this.schoolId,
    };

    return this._http.generic(SchoolUserEndpoints.ADD_USERS(dto));
  }

  grantUserOffer(schoolId: string, uid: string, offer: Offer) {
    const action = SchoolUserEndpoints.GRANT_USER_OFFER({
      schoolId,
      uid,
      offer,
    });

    return this._http.generic(action);
  }

  grantMultipleUsersOffer(schoolId: string, uids: string[], offerId: string) {
    const action = SchoolUserEndpoints.GRANT_MULTIPLE_USERS_OFFER({
      schoolId,
      uids,
      offerId,
    });

    return this._http.generic(action);
  }

  updateMailSubscription(
    uid: string,
    schoolId: string,
    mailsUnsubscribed: boolean,
  ) {
    return this._http.generic<SchoolUserNew>(
      SchoolUserEndpoints.UPDATE_MAIL_SUBSCRIPTION(schoolId, uid, {
        mailsUnsubscribed: mailsUnsubscribed || false,
      }),
    );
  }

  editUser(uid: string, user: EditUser): Observable<any> {
    return this._http.put(
      SchoolUserEndpoints.UPDATE_USER(this.schoolId, uid),
      user,
    );
  }

  setModeratedSpaces(schoolId: string, uid: string, spaceIds: string[]) {
    return this._http.generic(
      SchoolUserEndpoints.SET_MODERATED_SPACES({ schoolId, uid, spaceIds }),
    );
  }

  setSchoolUserSettings(
    schoolId: string,
    uid: string,
    upt: {
      spaceIds: string[];
      userDashboards: string[];
      startDasboardId: string | null;
    },
  ) {
    return this._http
      .generic<SchoolUserNew>(
        SchoolUserEndpoints.SET_SCHOOL_USER_SETTINGS({
          schoolId,
          uid,
          ...upt,
        }),
      )
      .pipe(
        tap((updatedUser) =>
          this._schoolUserService.updateUserInStore(updatedUser),
        ),
      );
  }

  resendWelcomeMessage(schoolId: string, uid: string) {
    const data: ResendUserWelcomeMessageData = {
      schoolId,
      uid,
    };

    return this._http.post(
      SchoolUserEndpoints.RESEND_WELCOME_MESSAGE + `/${schoolId}/${uid}`,
      data,
    );
  }

  triggeChapterAccess(
    schoolId: string,
    uid: string,
    courseId: string,
    chapterId: string,
    currentAccess: boolean,
  ) {
    return this._http.generic(
      SchoolUserEndpoints.CHAPTER_ACCESS(schoolId, {
        schoolId,
        uid,
        courseId,
        chapterId,
        active: !currentAccess,
      }),
    );
  }

  sendDripMessage(data: DripMessageData) {
    const callable = httpsCallable(this._fns, CF.SCHOOL_USERS.SEND_DRIPMESSAGE);

    callable(data);
  }

  updateAccessExpiry(
    schoolId: string,
    uid: string,
    offerId: string,
    hasOfferId: string,
    upt: UserAccessExpiryChanged,
  ) {
    const data: UpdateUserAccessExpiryDto = {
      schoolId,
      uid,
      hasOfferId,
      offerId,
      expires: upt.expires,
      expiresAt: upt.expiresAt as any,
    };

    return this._http.generic(
      SchoolUserEndpoints.UPDATE_USER_ACCESS_EXPIRY(data),
    );
  }

  updateExternalOrderNumber(
    schoolId: string,
    uid: string,
    hasOfferId: string,
    orderId?: string | null,
  ): Observable<any> {
    const dto: UpdateOrderIdDto = {
      schoolId,
      uid,
      hasOfferId,
      orderId: orderId || null,
    };

    return this._http.generic(SchoolUserEndpoints.UPDATE_OFFERID(dto));
  }

  updateStartDate(
    schoolId: string,
    uid: string,
    hasCourse: UserHasCourseNew,
    courseId: string,
    startDate: Date,
    deleteDueMessages: boolean,
  ) {
    this._updateDripMessageTasks(
      schoolId,
      uid,
      hasCourse,
      courseId,
      startDate,
      deleteDueMessages,
    );

    const dto: UpdateStartDateDto = {
      schoolId,
      uid,
      courseId,
      startDate,
    };

    return this._http.generic(SchoolUserEndpoints.UPDATE_START_DATE(dto));
  }

  updateLastLogin(school: School) {
    const data: UpdateAdminLastLoginDto = {
      schoolId: school.id,
      schoolName: school.name,
      version: this._appVersion || '',
      host: this._window.location.hostname,
    };

    this._http.generic(AdminUserEndpoints.UPDATE_LAST_LOGIN(data)).subscribe();
  }

  recalculateProgress(schoolId: string, uid: string) {
    return this._http.generic(
      AdminUserEndpoints.RECALCULATE_PROGRESS(schoolId, uid),
    );
  }

  setCustomProperties(
    schoolId: string,
    userId: string,
    customProperties: UserCustomPropertyValue[],
  ): Observable<SchoolUserNew> {
    return this._http.generic<SchoolUserNew>(
      SchoolUserEndpoints.SET_CUSTOM_PROPERTIES(schoolId, userId, {
        properties: customProperties,
      }),
    );
  }

  getSchoolsWhereUserIsOwner() {
    return this._http.generic<SchoolOwner[]>(
      AdminUserEndpoints.GET_SCHOOLS_USER_IS_OWNER_IN(),
    );
  }

  deleteProfile() {
    return this._http.generic(AdminUserEndpoints.DELETE_PROFILE());
  }

  private _updateDripMessageTasks(
    schoolId: string,
    uid: string,
    hasCourse: UserHasCourseNew,
    courseId: string,
    startDate: Date,
    deleteDueMessages: boolean,
  ) {
    const oldDate = new Date(hasCourse?.startDate);
    const difference = DateHelpers.dateDiffInDays(oldDate, startDate);

    const dripUpt: UpdateDripMessagesData = {
      schoolId,
      uid,
      courseId,
      difference,
      deleteDueMessages,
    };

    const callable = httpsCallable(
      this._fns,
      CF.SCHOOL_USERS.UPDATE_DRIPMESSAGE_TASKS,
    );

    callable(dripUpt);
  }
}
