import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  SchoolUserEndpoints,
  SchoolUserNew,
} from '@memberspot/shared/model/school-user';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BreadcrumbService } from 'xng-breadcrumb';

import { SchoolUsersStore } from './state/school-users.store';

@Injectable({
  providedIn: 'root',
})
export class SchoolUserService {
  constructor(
    private _http: HttpBackendService,
    private _breadcrumbService: BreadcrumbService,
    private _store: SchoolUsersStore,
  ) {}

  syncUser(schoolId: string, uid: string): Observable<SchoolUserNew> {
    this._store.setLoading(true);

    return this._http
      .generic<SchoolUserNew>(
        SchoolUserEndpoints.GET_SCHOOL_USER(schoolId, uid),
      )
      .pipe(
        tap((user) => this.updateUserInStore(user)),
        tap((user) => this._updateBreadcrumb(user)),
      );
  }

  removeDevice(visitorId: string) {
    this._store.updateActive((activeUser) => ({
      ...activeUser,
      devices: activeUser.devices?.filter((d) => d.visitorId !== visitorId),
    }));
  }

  updateUserInStore(user: SchoolUserNew) {
    if (user?.uid) {
      this._store.upsert(user.uid, user);
      this._store.setActive(user.uid);
    }

    this._store.setLoading(false);
  }

  private _updateBreadcrumb(u: SchoolUserNew) {
    return u && this._breadcrumbService.set('@userName', this._getName(u));
  }

  private _getName(user: SchoolUserNew) {
    const displayName = `${user?.firstname} ${user?.name}`;

    return /^\s*$/.test(displayName) ? user.email : displayName;
  }
}
