import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SchoolUserNew } from '@memberspot/shared/model/school-user';

export type SchoolUsersState = EntityState<SchoolUserNew>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'school-users' })
export class SchoolUsersStore extends EntityStore<
  SchoolUsersState,
  SchoolUserNew
> {
  constructor() {
    super();
  }
}
