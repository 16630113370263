import { BackendMethod } from '@memberspot/shared/model/backend';
import type {
  GetUserCourseChapterDto,
  GetUserCourseDto,
  GetUserCourseProgressDto,
  PostUserDto,
} from '@memberspot/shared/model/school-user';

const UserCourseEndpoints = {
  GET_USER_COURSE: (data: GetUserCourseDto) => ({
    url: `/courses/user-course/${data.schoolId}/${data.courseId}`,
    method: BackendMethod.GET,
  }),
  GET_USER_CHAPTER: (data: GetUserCourseChapterDto) => ({
    url: `/courses/user-course/chapter/${data.schoolId}/${data.courseId}/${data.chapterId}`,
    method: BackendMethod.GET,
  }),
  GET_LAST_ACTIONS: (data: GetUserCourseProgressDto) => ({
    url: `/courses/last-actions/${data.schoolId}/${data.uid}/${data.courseId}`,
    method: BackendMethod.GET,
  }),
};

const UserProgressEndpoints = {
  GET_COURSE_PROGRESS: (data: GetUserCourseProgressDto) => ({
    url: `/school-user-progress/user-course-progress/${data.schoolId}/${data.uid}/${data.courseId}`,
    method: BackendMethod.GET,
  }),
  GET_CHAPTER_PROGRESS: (
    schoolId: string,
    courseId: string,
    chapterId: string,
    uid: string,
  ) => ({
    url: `/school-user-progress/user-course-progress/${schoolId}/${uid}/${courseId}/${chapterId}`,
    method: BackendMethod.GET,
  }),
  RESET_USER_POST: (data: PostUserDto) => ({
    url: `/school-user-progress/user-course-progress/reset-user-post`,
    method: BackendMethod.POST,
    data,
  }),
  COMPLETE_USER_POST: (data: PostUserDto) => ({
    url: `/school-user-progress/user-course-progress/complete-user-post`,
    method: BackendMethod.POST,
    data,
  }),
};

export const AdminUserCourseEndpoints = Object.freeze({
  USER_COURSE: UserCourseEndpoints,
  USER_PROGRESS: UserProgressEndpoints,
});
