enum AdminUserCloudFunctions {
  CHECK_ADMIN_TOKEN = 'adminUser-checkAdminToken',
}

enum SchoolCloudFunctions {
  CREATE_SCHOOL_CUSTOMER = 'school-createSchoolCustomer',
}

enum SchoolUserCloudFunctions {
  GRANT_USER_OFFER = 'schoolUserAccess-grantUserOffer',

  SEND_DRIPMESSAGE = 'schoolUser-sendDripMessage',
  UPDATE_DRIPMESSAGE_TASKS = 'schoolUserAccess-updateDripMessageTasks',
}

export const CF = Object.freeze({
  ADMIN_USERS: AdminUserCloudFunctions,
  SCHOOL: SchoolCloudFunctions,
  SCHOOL_USERS: SchoolUserCloudFunctions,
});
